<div *ngIf="canGoToHome()" class="d-flex" id="wrapper">
	<div id="sidebar">
		<app-sidebar
			(closeMobileSidebar)="closeMobileSidebar($event)"
		></app-sidebar>
	</div>

	<div id="page-content-wrapper">
		<app-navbar [pageName]="pageName"></app-navbar>
		<div class="container-fluid content-style">
			<div class="business-info mt-2">
				<strong>{{ getCompanyName() }}</strong>
			</div>
			<ng-container *ngTemplateOutlet="router"></ng-container>
		</div>
	</div>
</div>

<div *ngIf="!canGoToHome()" class="vh-100">
	<div *ngIf="isUserSupportAndIsNotLoggedIn()">
		<app-navbar></app-navbar>
	</div>
	<ng-container *ngTemplateOutlet="router"></ng-container>
</div>

<ng-template #router>
	<ng-content></ng-content>
</ng-template>
