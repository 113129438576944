/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const t = t => (e, o) => {
  void 0 !== o ? o.addInitializer(() => {
    customElements.define(t, e);
  }) : customElements.define(t, e);
};
export { t as customElement };
