import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
	EnvironmentConfig,
	ENV_CONFIG
} from "@core/environments/environment-config";
import { skipAuth } from "@core/interceptors/token.interceptor";
import { BehaviorSubject, map, Observable, of, Subject, switchMap } from "rxjs";
import { ignoreHttpError } from "@core/interceptors/http-error.interceptor";
import { RevalidateSignature } from "@data/schemas/validate-signature";

interface IpInfo {
	ip: string;
}

@Injectable({
	providedIn: "root"
})
export class ValidateSignatureService {
	API_URL = "";

	private revalidateSignature = new BehaviorSubject<boolean>(false);
	revalidateSignature$ = this.revalidateSignature.asObservable();

	private destroyRevalidateSignature = new Subject<boolean>();
	destroyRevalidateSignature$ =
		this.destroyRevalidateSignature.asObservable();

	private restartRevalidateSignature = new BehaviorSubject<boolean>(false);
	restartRevalidateSignature$ =
		this.destroyRevalidateSignature.asObservable();

	constructor(
		private http: HttpClient,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {
		this.API_URL = `${config.environment.API}`;
	}

	private getIpUser(): Observable<string> {
		return this.http
			.get<IpInfo>("https://api.ipify.org?format=json", {
				context: skipAuth()
			})
			.pipe(map((result) => result.ip));
	}

	private validateSignatureStatus(ip: string): Observable<unknown> {
		const params = new HttpParams().set("ipAddress", ip);

		return this.http.get(
			`${this.API_URL}/users/validate-signature-status`,
			{ params, context: ignoreHttpError() }
		);
	}

	validateSignature(isAsaasLogin?: boolean): Observable<unknown> {
		const ipSource = isAsaasLogin ? of("0.0.0.0") : this.getIpUser();
		return ipSource.pipe(
			switchMap((ip) => this.validateSignatureStatus(ip))
		);
	}

	revalidateSignatureStatus(): Observable<RevalidateSignature> {
		return this.http.get<RevalidateSignature>(
			`${this.API_URL}/users/revalidate-signature-status`,
			{ context: ignoreHttpError() }
		);
	}

	fireRevalidateSignature(status: boolean): void {
		this.revalidateSignature.next(status);
	}

	fireDestroyRevalidateSignature(): void {
		this.destroyRevalidateSignature.next(true);
	}

	fireRestartRevalidateSignature(status: boolean): void {
		this.restartRevalidateSignature.next(status);
	}
}
