import {
	APP_INITIALIZER,
	DEFAULT_CURRENCY_CODE,
	LOCALE_ID,
	NgModule,
	Optional,
	SkipSelf
} from "@angular/core";
import {
	CommonModule,
	HashLocationStrategy,
	LocationStrategy
} from "@angular/common";
import {
	HttpClientModule,
	HttpErrorResponse,
	HTTP_INTERCEPTORS
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxRolesService } from "ngx-permissions";

import { throwIfAlreadyLoaded } from "./guards/module-import.guard";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { EnvironmentModule } from "./environments/environment.module";
import { environment } from "@env";
import { HttpErrorInterceptor } from "./interceptors/http-error.interceptor";
import { PermissionService } from "./services/auth/permission.service";
import { AuthService } from "./services/auth/auth.service";
import { registerLocaleData } from "@angular/common";
import ptBr from "@angular/common/locales/pt";
import { TranslateCacheInterceptor } from "./interceptors/translate-cache.interceptor";
import { MenuService } from "./services/auth/menu.service";
import { switchMap } from "rxjs";
import { DatadogLoggingInterceptor } from "@core/interceptors/datadog-logging.Interceptor";
import { UrlBffInterceptor } from "@core/interceptors/url-bff.interceptor";

registerLocaleData(ptBr);

function loadPermissions(
	roles: NgxRolesService,
	permissionService: PermissionService,
	auth: AuthService,
	menuService: MenuService
) {
	return () => {
		const promise = new Promise((resolve: (value: boolean) => void) => {
			if (auth.isLoggedIn()) {
				menuService
					.loadMenus()
					.pipe(
						switchMap((menu) => {
							menuService.setMenuList(menu);
							return permissionService.loadPermissionsV2();
						})
					)
					.subscribe({
						next: (permission) => {
							roles.addRoleWithPermissions(
								permission.name ?? "",
								[
									...permission.modules,
									...permission.pages,
									...permission.actions,
									auth.getUserType()
								]
							);
							resolve(true);
						},
						error: (error: HttpErrorResponse) => {
							console.error(error);
							if (!auth.isLoginFromAsaas()) {
								auth.logout().subscribe();
							}
							resolve(false);
						}
					});
			} else {
				resolve(true);
			}
		});

		return promise;
	};
}

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		BrowserAnimationsModule,
		EnvironmentModule.forRoot({ environment })
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: loadPermissions,
			deps: [
				NgxRolesService,
				PermissionService,
				AuthService,
				MenuService
			],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UrlBffInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TranslateCacheInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DatadogLoggingInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true
		},
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: Window, useValue: window },
		{ provide: LOCALE_ID, useValue: "pt-BR" },
		{ provide: DEFAULT_CURRENCY_CODE, useValue: "BRL" }
	]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, "CoreModule");
	}
}
