import { noChange as R } from "../lit-html.js";
import { directive as e, Directive as i, PartType as t } from "../directive.js";
/**
 * @license
 * Copyright 2018 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const ee = "important",
  ie = " !" + ee,
  se = e(class extends i {
    constructor(e) {
      if (super(e), e.type !== t.ATTRIBUTE || "style" !== e.name || e.strings?.length > 2) throw Error("The `styleMap` directive must be used in the `style` attribute and must be the only part in the attribute.");
    }
    render(t) {
      return Object.keys(t).reduce((e, r) => {
        const s = t[r];
        return null == s ? e : e + `${r = r.includes("-") ? r : r.replace(/(?:^(webkit|moz|ms|o)|)(?=[A-Z])/g, "-$&").toLowerCase()}:${s};`;
      }, "");
    }
    update(t, [e]) {
      const {
        style: r
      } = t.element;
      if (void 0 === this.ft) return this.ft = new Set(Object.keys(e)), this.render(e);
      for (const t of this.ft) null == e[t] && (this.ft.delete(t), t.includes("-") ? r.removeProperty(t) : r[t] = null);
      for (const t in e) {
        const s = e[t];
        if (null != s) {
          this.ft.add(t);
          const e = "string" == typeof s && s.endsWith(ie);
          t.includes("-") || e ? r.setProperty(t, e ? s.slice(0, -11) : s, e ? ee : "") : r[t] = s;
        }
      }
      return R;
    }
  });
export { se as styleMap };
