import { Injectable } from "@angular/core";
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";

import {
	EnvironmentConfig,
	ENV_CONFIG
} from "@core/environments/environment-config";
import { Inject } from "@angular/core";
import { AuthService } from "@core/services/auth/auth.service";

@Injectable()
export class UrlBffInterceptor implements HttpInterceptor {
	private readonly EXCLUDED_URLS = ["http", "https", "/assets"];

	constructor(
		private authService: AuthService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig
	) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		if (this.shouldSkip(request.url)) {
			return next.handle(request);
		}

		let url = this.prepareUrl(request.url);

		if (this.shouldAddHashedFingerprint(url)) {
			url = this.addHashedFingerprint(url);
		}

		const clonedRequest = request.clone({ url });

		return next.handle(clonedRequest);
	}

	private shouldSkip(url: string): boolean {
		return this.EXCLUDED_URLS.some((prefix) => url.startsWith(prefix));
	}

	private prepareUrl(url: string): string {
		if (!url.startsWith("/")) {
			url = `/${url}`;
		}

		return `${this.config.environment.API_BFF}${url}`;
	}

	private shouldAddHashedFingerprint(url: string): boolean {
		return !url.includes("hashedFingerprint=");
	}

	private addHashedFingerprint(url: string): string {
		const hasQueryParams = url.includes("?");
		const hashedFingerprint = this.authService.getHashedFingerprint() ?? "";

		return `${url}${hasQueryParams ? "&" : "?"}hashedFingerprint=${hashedFingerprint}`;
	}
}
