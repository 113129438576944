import { Injectable } from "@angular/core";
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpResponse,
	HttpErrorResponse,
	HttpHeaders
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { DatadogService } from "@core/services/datadog.service";

@Injectable()
export class DatadogLoggingInterceptor implements HttpInterceptor {
	constructor(private datadogService: DatadogService) {}

	intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		this.datadogService.customLog(
			"info",
			`HttpRequest (${req.method}) - ${req.url}`,
			{
				url: req.url,
				headers: this.maskSensitiveData(
					this.headersToObject(req.headers)
				),
				body: this.maskSensitiveData(req.body)
			}
		);

		return next.handle(req).pipe(
			tap((event) => {
				if (event instanceof HttpResponse) {
					this.datadogService.customLog(
						"info",
						`HttpResponse (${event.status}) - ${req.url}`,
						{
							url: req.url,
							status: `${event.status} - ${event.statusText}`,
							headers: this.maskSensitiveData(
								this.headersToObject(event.headers)
							),
							body: this.maskSensitiveData(event.body)
						}
					);
				}
			}),
			catchError((error: HttpErrorResponse) => {
				this.datadogService.customLog(
					"error",
					`HttpErrorResponse (${error.status}) - ${req.url}`,
					{
						url: req.url,
						headers: this.maskSensitiveData(
							this.headersToObject(error.headers)
						),
						status: `${error.status} - ${error.statusText}`,
						error: error.error as unknown
					}
				);

				return throwError(() => error);
			})
		);
	}

	private maskSensitiveData(obj: unknown): unknown {
		const sensitiveKeys = [
			"senha",
			"password",
			"apiKey",
			"appId",
			"cpf",
			"cnpj",
			"companyTaxPayerRegistration",
			"token",
			"jwt",
			"chave",
			"credencial",
			"Authorization"
		];
		const newObj = { ...(obj as Record<string, unknown>) };

		for (const key in newObj) {
			if (Object.prototype.hasOwnProperty.call(newObj, key)) {
				if (
					typeof (newObj as Record<string, unknown>)[key] ===
						"object" &&
					(newObj as Record<string, unknown>)[key] !== null
				) {
					(newObj as Record<string, unknown>)[key] =
						this.maskSensitiveData(
							(newObj as Record<string, unknown>)[key]
						);
				} else if (sensitiveKeys.includes(key)) {
					(newObj as Record<string, unknown>)[key] = "***";
				}
			}
		}

		return newObj;
	}

	private headersToObject(headers: HttpHeaders): Record<string, unknown> {
		const headersObj: Record<string, unknown> = {};
		(headers?.keys?.() ?? [])?.forEach((key) => {
			headersObj[key] = headers.get(key);
		});
		return headersObj;
	}
}
