import { defaultConverter as t, notEqual as e } from "../reactive-element.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const o = {
    attribute: !0,
    type: String,
    converter: t,
    reflect: !1,
    hasChanged: e
  },
  r = (t = o, e, r) => {
    const {
      kind: n,
      metadata: i
    } = r;
    let s = globalThis.litPropertyMetadata.get(i);
    if (void 0 === s && globalThis.litPropertyMetadata.set(i, s = new Map()), s.set(r.name, t), "accessor" === n) {
      const {
        name: o
      } = r;
      return {
        set(r) {
          const n = e.get.call(this);
          e.set.call(this, r), this.requestUpdate(o, n, t);
        },
        init(e) {
          return void 0 !== e && this.P(o, void 0, t), e;
        }
      };
    }
    if ("setter" === n) {
      const {
        name: o
      } = r;
      return function (r) {
        const n = this[o];
        e.call(this, r), this.requestUpdate(o, n, t);
      };
    }
    throw Error("Unsupported decorator location: " + n);
  };
function n(t) {
  return (e, o) => "object" == typeof o ? r(t, e, o) : ((t, e, o) => {
    const r = e.hasOwnProperty(o);
    return e.constructor.createProperty(o, r ? {
      ...t,
      wrapped: !0
    } : t), r ? Object.getOwnPropertyDescriptor(e, o) : void 0;
  })(t, e, o);
}
export { n as property, r as standardProperty };
