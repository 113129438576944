import { inject } from "@angular/core";
import { CanMatchFn, Route, Router, UrlSegment } from "@angular/router";
import { AuthService } from "@core/services/auth/auth.service";

export const loggedInAuthGuardFn: CanMatchFn = (_: Route, __: UrlSegment[]) => {
	const authService = inject(AuthService);
	const router = inject(Router);

	if (authService.getTokenSupportAccountant()) {
		if (authService.isSupportLoggedInAsNormalUser()) {
			authService.removeUserToken();
			authService.removeSupportLoggedInAsNormalUser();
		}
		return router.parseUrl("/suporte");
	}

	if (authService.isLoggedOut() && !authService.getTokenSupportAccountant()) {
		return true;
	}
	return router.parseUrl("/home");
};
