import { property as t } from "./property.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function r(r) {
  return t({
    ...r,
    state: !0,
    attribute: !1
  });
}
export { r as state };
