import {
	Component,
	HostListener,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Renderer2
} from "@angular/core";
import {
	ENV_CONFIG,
	EnvironmentConfig
} from "@core/environments/environment-config";
import { AuthService } from "@core/services/auth/auth.service";
import { ValidateSignatureService } from "@core/services/auth/validate-signature.service";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { RouterSupportService } from "@core/services/router-support.service";
import { LocalStorageService } from "@core/services/storage/local-storage.service";
import { BusinessPadrao } from "@data/schemas/business";
import { BusinessService } from "@data/services/business.service";
import { LogEmailsService } from "@data/services/log-emails.service";
import { MonthlyCustomerService } from "@data/services/monthly-customer.service";
import { ConfigDashboardComponent } from "@modules/preferences/pages/config-dashboard/config-dashboard.component";
import { CreateAccountAsaasModalComponent } from "@modules/preferences/pages/create-account-asaas-modal/create-account-asaas-modal.component";
import { AlertsService } from "@shared/components/atlas/alerts/services";
import { LoginResponse } from "@shared/schemas/complete-login";
import { UserStorage } from "@shared/schemas/enums/user-storage";
import { ChatIntercomService } from "@shared/services/chat-intercom.service";
import { LoadScriptService } from "@shared/services/load-script.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject, switchMap } from "rxjs";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, OnDestroy {
	@Input() pageName = "";

	modalRef?: BsModalRef;
	userLinkActivated = false;
	centralAppsLinkActivated = false;
	userEmail = "";
	userAvatar = "";
	isMobile = false;
	showNavbarContent = true;
	mobileDropdownVisible = false;
	mobileSidebarVisible = false;
	menuTitle = "";
	emailsWithError = 0;

	ASAAS_LOGIN_URL = "";
	ASAAS_FORGOT_PASSWORD_URL = "";

	private wasInside = false;
	private _onDestroy = new Subject<boolean>();

	constructor(
		private authService: AuthService,
		private logEmailsService: LogEmailsService,
		private localStorage: LocalStorageService,
		private routerSupport: RouterSupportService,
		private modalService: BsModalService,
		private chatIntercomService: ChatIntercomService,
		private loadScriptService: LoadScriptService,
		private renderer: Renderer2,
		private businessService: BusinessService,
		private validateSignatureService: ValidateSignatureService,
		private segment: CustomSegmentService,
		private monthlyCustomerService: MonthlyCustomerService,
		private alertsService: AlertsService,
		@Inject(ENV_CONFIG) private config: EnvironmentConfig,
		private bsModalService: BsModalService
	) {
		this.ASAAS_LOGIN_URL = `${this.config.environment.ASAAS_LOGIN_URL}`;
		this.ASAAS_FORGOT_PASSWORD_URL = `${this.config.environment.ASAAS_FORGOT_PASSWORD_URL}`;
	}

	ngOnInit(): void {
		this.changeEmailUser();
		this.getUserAvatar();
		this.showChatIntercom();
		this.getEmailsWithError();

		this.pageName = this.localStorage.get<string>("submenuName") ?? "";
		if (!this.pageName) {
			this.pageName = "SIDEBAR.DASHBOARD.TITLE";
		}

		this.onResize();
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
		this.localStorage.remove("submenuName");
	}

	private getEmailsWithError() {
		if (
			!this.authService.getUserSupport() ||
			this.authService.isSupportLoggedInAsNormalUser()
		) {
			this.logEmailsService
				.emailsWithError()
				.subscribe(
					(emailsWithError) =>
						(this.emailsWithError = emailsWithError)
				);
		}
	}

	private changeEmailUser(): void {
		if (this.authService.getUserSupport()) {
			this.userEmail = this.authService.getUserSupport()?.email ?? "";
			return;
		}
		this.userEmail =
			this.localStorage.get<LoginResponse>(UserStorage.UserLogin)
				?.email ?? "";
	}

	private getUserAvatar(): void {
		if (this.authService.getUserSupport()) {
			this.userAvatar = this.authService.getUserSupport()?.linkFoto ?? "";
			return;
		}
		this.userAvatar =
			this.localStorage.get<LoginResponse>(UserStorage.UserLogin)
				?.linkFoto ?? "";
	}

	private showChatIntercom(): void {
		if (this.authService.isLoggedIn()) {
			this.chatIntercomService.bootChatIntercom();
		}
	}

	activateUserLink(): void {
		this.userLinkActivated = !this.userLinkActivated;
		this.centralAppsLinkActivated = false;
		this.wasInside = true;
	}

	activateCentralAppsLink(): void {
		this.centralAppsLinkActivated = !this.centralAppsLinkActivated;
		this.userLinkActivated = false;
		this.wasInside = true;
	}

	@HostListener("document:click")
	clickout(): void {
		if (!this.wasInside) {
			this.centralAppsLinkActivated = this.userLinkActivated = false;
		}
		this.wasInside = false;
	}

	openModalConfigDashboard(): void {
		this.modalService.show(ConfigDashboardComponent, {});
	}

	whichLogoutToUse(): Observable<unknown> {
		return this.authService.isSupportLoggedInAsNormalUser()
			? this.authService
					.logoutLegacy()
					.pipe(switchMap(() => this.authService.logoutSupport()))
			: this.authService
					.logoutLegacy()
					.pipe(switchMap(() => this.authService.logout()));
	}

	logout(): void {
		this.whichLogoutToUse().subscribe({
			next: () => {
				if (this.routerSupport.getLocationSupportCurrentValue()) {
					this.authService.logoutUserSupportAccountant();
				}
			},
			complete: () => {
				this.validateSignatureService.fireDestroyRevalidateSignature();
				if (this.authService.isSupportLoggedInAsNormalUser()) {
					this.authService.removeSupportLoggedInAsNormalUser();
				}
			}
		});
	}

	showMobileSidebar(): void {
		this.mobileSidebarVisible = !this.mobileSidebarVisible;
	}

	closeMobileSidebar(submenuName: string): void {
		this.mobileSidebarVisible = false;
		if (submenuName) {
			this.pageName = submenuName;
			this.localStorage.set<string>("submenuName", submenuName);
		}
	}

	openMobileDropdown(menuTitle: string): void {
		this.menuTitle = menuTitle;
		this.mobileDropdownVisible = !this.mobileDropdownVisible;
		if (this.isMobile) {
			this.showNavbarContent = false;
		}
	}

	closeMobileDropdown(): void {
		this.showNavbarContent = true;
		this.mobileDropdownVisible = false;
	}

	@HostListener("window:resize")
	onResize(): void {
		const screenWidth = window.innerWidth;
		this.isMobile = screenWidth < 992 ? true : false;

		this.showNavbarContent =
			this.isMobile && this.mobileDropdownVisible ? false : true;
	}

	getBusinessData(): BusinessPadrao | null {
		return this.businessService.getBusinessData();
	}

	canShowBusinessInfoNavbar(): boolean {
		if (this.authService.getUserSupport()) {
			return this.authService.isSupportLoggedInAsNormalUser();
		}
		return true;
	}

	canShowAsaasAppsCenter(): boolean {
		if (this.authService.getUserSupport()) {
			return Boolean(this.authService.getUserSupport()?.showCentralAsaas);
		}

		return Boolean(
			this.localStorage.get<LoginResponse>(UserStorage.UserLogin)
				?.showCentralAsaas
		);
	}

	openModalCreateAccountAsaas() {
		this.modalRef = this.bsModalService.show(
			CreateAccountAsaasModalComponent
		);
		this.modalRef.setClass("modal-md modal-dialog-centered");
	}

	segmentTrackTraning(): void {
		this.segment.track({
			table: "traning",
			screen: "Toolbar",
			action: "Click Link Treinamento"
		});
	}
}
