import { inject } from "@angular/core";
import { CanMatchFn, Route, Router, UrlSegment } from "@angular/router";
import { AuthService } from "@core/services/auth/auth.service";

export const isSupportGuardFn: CanMatchFn = (_: Route, __: UrlSegment[]) => {
	const authService = inject(AuthService);
	const router = inject(Router);

	if (authService.getUserSupport()) {
		return true;
	}
	return router.parseUrl("/home");
};
