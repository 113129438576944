import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class RouterSupportService {
	private isLocationSupport = new BehaviorSubject<boolean>(false);
	isLocationSupport$ = this.isLocationSupport.asObservable();

	setIsLocationSupport(status: boolean): void {
		this.isLocationSupport.next(status);
	}

	getLocationSupportCurrentValue(): boolean {
		return this.isLocationSupport.value;
	}

	isUrlSupport(url: string): void {
		if (url.includes("suporte")) {
			this.setIsLocationSupport(true);
			return;
		}
		this.setIsLocationSupport(false);
	}
}
