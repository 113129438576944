import { Injectable } from "@angular/core";
import { LocalStorageService } from "@core/services/storage/local-storage.service";
import { BehaviorSubject, distinctUntilChanged } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class SidebarMenuService {
	private actualMenu = new BehaviorSubject<string>("");
	actualMenu$ = this.actualMenu.asObservable().pipe(distinctUntilChanged());

	private readonly localStorageBadgeKey = "optionWasClicked";

	constructor(private localStorage: LocalStorageService) {}

	get optionWasClicked(): boolean {
		return this.localStorage.get(this.localStorageBadgeKey) === "true";
	}

	markClickedButton(): void {
		this.localStorage.set(this.localStorageBadgeKey, "true");
		return;
	}

	setActualmenu(menu: string): void {
		this.actualMenu.next(menu);
		return;
	}
}
