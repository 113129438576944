import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from "@angular/core";
import { MenuService } from "@core/services/auth/menu.service";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { PreferencesService } from "@data/services/preferences.service";
import { CriarPedidoDeVendaComponent } from "@modules/commercial/components/criar-pedido-de-venda/criar-pedido-de-venda.component";
import { CriarPropostaComercialComponent } from "@modules/commercial/components/criar-proposta-comercial/criar-proposta-comercial.component";
import { CreateNewReceiptWizardComponent } from "@modules/financeiro/components/create-new-receipt-wizard/create-new-receipt-wizard.component";
import { KeyDashbordPreferences } from "@shared/schemas/enums/preference";
import { SidebarSubmenuItem } from "@shared/schemas/sidebar-submenu";
import { formatUrlJsf } from "@shared/utils/format-url-jsf";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { firstValueFrom, Observable, Subject, takeUntil } from "rxjs";
import { PostMessageService } from "@shared/services/post-message.service";
import { PermissionService } from "@core/services/auth/permission.service";
import { QuickAccessPermissions } from "@enums/quick-access-permissions.enum";
import { Permission } from "@shared/schemas/permission";
import { environment } from "@env";
import { AuthService } from "@core/services/auth/auth.service";
import { HotspotService } from "@data/services/hotspot.service";
import { v4 as uuidv4 } from "uuid";
import { WizardReceivableService } from "@data/services/wizard-receivable.service";

@Component({
	selector: "app-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, OnDestroy {
	sidebarExpanded = true;
	sidebarPinned = true;
	quickAccessPermissions = QuickAccessPermissions.NotAllowed;
	isProduction = false;
	idUsuario: number | undefined = undefined;
	hasHotspotAccountsReceivable: boolean = false;

	@Input() isMobile = false;
	@Output() closeMobileSidebar = new EventEmitter<string>();

	submenusComercial: SidebarSubmenuItem[] = [];
	submenusContabilidadeOnline: SidebarSubmenuItem[] = [];
	submenusFaturamento: SidebarSubmenuItem[] = [];
	submenusFinanceiro: SidebarSubmenuItem[] = [];
	submenusPontoVenda: SidebarSubmenuItem[] = [];
	submenusProducao: SidebarSubmenuItem[] = [];
	submenusServicos: SidebarSubmenuItem[] = [];
	submenusSuprimentos: SidebarSubmenuItem[] = [];
	submenusRelatorios: SidebarSubmenuItem[] = [];

	NAME_HOTSPOT_ACCOUNTS_RECEIVABLE = "accounts_receivable";

	modalRef?: BsModalRef;

	private _onDestroy$ = new Subject<boolean>();

	constructor(
		private preferencesService: PreferencesService,
		private modalService: BsModalService,
		private menuService: MenuService,
		private customSegmentService: CustomSegmentService,
		private postMessageService: PostMessageService,
		private permissionService: PermissionService,
		public authService: AuthService,
		private hotspotService: HotspotService,
		private wizardReceivableService: WizardReceivableService
	) {}

	ngOnInit(): void {
		if (!this.isMobile) {
			this.getShowMenuConfigDatabaseStatus();
		}

		this.isProduction = environment.production;
		this.idUsuario = this.authService.getUserLogin()?.idUsuario;

		this.postMessageService.onNewMessage$
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((message) => {
				if (message.intention === "openCommercialProposalWizard") {
					this.openModal("proposta_comercial");
				}
				if (message.intention === "openReceiptWizard") {
					void this.openWizard("financial_management");
				}
				if (message.intention === "openReceiptWizardLinkedItem") {
					void this.wizardReceivableService.emitIsWizardOpenedByJsf(
						true
					);
				}
			});

		this.menuService.menuList$
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((menu) => {
				if (menu) {
					this.submenusComercial =
						this.formatUrlJsfBySidebarSubmenuItem(
							menu.submenusComercial
						);
					this.submenusContabilidadeOnline =
						this.formatUrlJsfBySidebarSubmenuItem(
							menu.submenusContabilidadeOnline
						);
					this.submenusFaturamento =
						this.formatUrlJsfBySidebarSubmenuItem(
							menu.submenusFaturamento
						);
					this.submenusFinanceiro =
						this.formatUrlJsfBySidebarSubmenuItem(
							menu.submenusFinanceiro
						);
					this.submenusPontoVenda =
						this.formatUrlJsfBySidebarSubmenuItem(
							menu.submenusPontodeVenda
						);
					this.submenusProducao =
						this.formatUrlJsfBySidebarSubmenuItem(
							menu.submenusProducao
						);
					this.submenusServicos =
						this.formatUrlJsfBySidebarSubmenuItem(
							menu.submenusServicos
						);
					this.submenusSuprimentos =
						this.formatUrlJsfBySidebarSubmenuItem(
							menu.submenusSuprimentos
						);
					this.submenusRelatorios =
						this.formatUrlJsfBySidebarSubmenuItem(
							menu.subMenusRelatorios
						);
				}
			});

		this.getQuickAccessPermissions();

		void this.initHotspot(this.NAME_HOTSPOT_ACCOUNTS_RECEIVABLE);
	}

	ngOnDestroy(): void {
		this._onDestroy$.next(true);
	}

	openWizard(where = "sidebar_menu"): void {
		const interaction_id = uuidv4();
		this.modalRef = this.modalService.show(
			CreateNewReceiptWizardComponent,
			{ initialState: { interactionId: interaction_id } }
		);
		this.modalRef.setClass("modal-wizard-atlas modal-fullscreen");

		if (!this.hasHotspotAccountsReceivable) {
			void this.setHotspot(this.NAME_HOTSPOT_ACCOUNTS_RECEIVABLE);

			void this.receivableHotspotSegment(
				this.NAME_HOTSPOT_ACCOUNTS_RECEIVABLE,
				"skip"
			);
		}

		this.wizardReceivableService.trackWizardOpenedOn(where, interaction_id);

		this.customSegmentService.track({
			table: "track_accounts_receivable",
			screen: "sidebar",
			action: "base_erp_accounts_receivable_new",
			properties: { interaction_id }
		});
	}

	openModal(type: "proposta_comercial" | "pedido_venda"): void {
		if (type === "proposta_comercial") {
			this.modalRef = this.modalService.show(
				CriarPropostaComercialComponent
			);
			this.modalRef.setClass("modal-fullscreen");
			this.customSegmentService.track({
				table: "base_create_wizard_sales_proposal_fullscreen",
				screen: "Menu Principal",
				action: "Botão Criar Proposta Comercial"
			});
			return;
		}
		this.modalRef = this.modalService.show(CriarPedidoDeVendaComponent);
		this.modalRef.setClass("modal-fullscreen");
	}

	private getQuickAccessPermissions(): void {
		this.permissionService
			.loadPermissionsV2()
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((permissions: Permission) => {
				this.quickAccessPermissions =
					this.determineQuickAccess(permissions);
			});
	}

	private determineQuickAccess(
		permissions: Permission
	): QuickAccessPermissions {
		const { modules, pages } = permissions;
		const hasCommercialModule = modules.includes("COMERCIAL");
		const hasFinancialModule = modules.includes("FINANCEIRO");
		const hasCommercialPage = pages.includes("PROPOSTACOMERCIAL");
		const hasFinancialPage =
			pages.includes("RELCONTASARECEBER") ||
			pages.includes("RELCONTASPAGARRECEBER");

		if (
			hasCommercialModule &&
			hasFinancialModule &&
			hasCommercialPage &&
			hasFinancialPage
		) {
			return QuickAccessPermissions.Both;
		} else if (hasCommercialModule && hasCommercialPage) {
			return QuickAccessPermissions.CreateCommercialProposal;
		} else if (hasFinancialModule && hasFinancialPage) {
			return QuickAccessPermissions.CreateReceipt;
		}

		return QuickAccessPermissions.NotAllowed;
	}

	private isJsfUrlAlreadyFormatted(jsfUrl: string): boolean {
		return jsfUrl.includes("http");
	}

	private formatUrlJsfBySidebarSubmenuItem(
		menus: Array<SidebarSubmenuItem>
	): Array<SidebarSubmenuItem> {
		menus.forEach((item) => {
			const page = item.jsfUrlPage ?? "";
			if (item.openNewTab && !this.isJsfUrlAlreadyFormatted(page)) {
				item.jsfUrlPage = formatUrlJsf(page, "");
				return;
			}
		});

		return menus;
	}

	private getShowMenuConfigDatabaseStatus(): void {
		this.preferencesService
			.getDashboardPreferences(KeyDashbordPreferences.MENU_ENCOLHIDO)
			.subscribe((status) => {
				if (status.value) {
					this.toggleSidebar();
				}
			});
	}

	private setShowMenuConfigDatabase(status: boolean): void {
		this.preferencesService
			.newDashboardPreference(
				KeyDashbordPreferences.MENU_ENCOLHIDO,
				status
			)
			.subscribe();
	}

	toggleSidebar(): void {
		if (!this.sidebarPinned) {
			this.setShowMenuConfigDatabase(false);
			this.pinSidebar();
		} else {
			this.setShowMenuConfigDatabase(true);
			this.sidebarPinned = false;
			this.hideSidebar();
		}
	}

	pinSidebar(): void {
		this.sidebarPinned = true;
		this.sidebarExpanded = true;
	}

	showSidebar(): void {
		this.sidebarExpanded = true;
	}

	hideSidebar(): void {
		if (!this.sidebarPinned) {
			this.sidebarExpanded = false;
		}
	}

	closeMobileSidebarMenu(submenuName: string): void {
		this.closeMobileSidebar.emit(submenuName);
	}

	hotspotClicked(origin: string, event: Event): void {
		event?.stopPropagation?.();
		void this.setHotspot(origin);
		void this.receivableHotspotSegment(origin, "open");
	}

	async initHotspot(name: string): Promise<void> {
		this.hasHotspotAccountsReceivable = !!(await this.getHotspot(
			`${name}_${this.idUsuario}`
		));
	}

	async getHotspot<Hotspot>(name: string): Promise<Hotspot | null> {
		const value = await firstValueFrom(
			this.hotspotService.getHotspot(name, "r2")
		);

		if (value && Object.keys(value).length > 0) {
			return <Hotspot>value;
		}
		return null;
	}

	async setHotspot(origin: string): Promise<void> {
		if (origin === this.NAME_HOTSPOT_ACCOUNTS_RECEIVABLE) {
			const oldHotspot = await this.getHotspot(
				`${origin}_${this.idUsuario}`
			);
			this.hasHotspotAccountsReceivable = !!(await firstValueFrom(
				this.hotspotService.saveOrUpdateHotspot({
					...(oldHotspot || {}),
					idUser: String(this.idUsuario),
					idPersistenceUnit:
						this.authService.getUserLoginData()
							?.idUnidadePersistencia,
					release: "r2",
					hotspotIdentification: origin,
					viewed: true
				}) as unknown as Observable<unknown>
			));
		}
	}

	async popoverClosed(origin: string): Promise<void> {
		if (origin == this.NAME_HOTSPOT_ACCOUNTS_RECEIVABLE) {
			this.hasHotspotAccountsReceivable = !!(await this.getHotspot(
				`${origin}_${this.idUsuario}`
			));
		}

		void this.receivableHotspotSegment(origin, "close");
	}

	async receivableHotspotSegment(
		origin: string,
		action: "open" | "skip" | "close"
	): Promise<void> {
		let properties = {};

		if (origin == this.NAME_HOTSPOT_ACCOUNTS_RECEIVABLE) {
			properties = (await this.getHotspot(
				`${this.NAME_HOTSPOT_ACCOUNTS_RECEIVABLE}_${this.idUsuario}`
			)) as { [k: string]: unknown };
		}

		this.customSegmentService.track({
			table: "track_accounts_receivable",
			screen: "sidebar",
			action: `base_erp_accounts_receivable_hotspot_${action}`,
			properties
		});
	}

	openWizardReceivableByHotspot(): void {
		this.customSegmentService.track({
			table: "track_accounts_receivable",
			screen: "sidebar",
			action: `base_erp_accounts_receivable_hotspot_new_receivable`
		});

		this.openWizard();
	}
}
