<nav class="navbar navbar-expand navbar-light bg-white sticky-top">
	<app-alerts
		key="floating-alert-create-account-asaas"
		[toastMode]="true"
		[stickyToast]="false"
		[timeToHideToast]="5000"
	>
		<ng-template appAutomataTemplate="alertBody" let-alert>
			<i class="bi icon-alert bi-check2"></i>
			{{
				"NAVBAR.NAVIGATION_CENTRAL.CREATE_ACCOUNT_ASAAS.SUCCESS_MESSAGE_INIT"
					| translate
			}}
			<a
				class="forgot-password-asaas"
				[href]="ASAAS_FORGOT_PASSWORD_URL"
				title="{{
					'NAVBAR.NAVIGATION_CENTRAL.CREATE_ACCOUNT_ASAAS.FORGOT_PASSWORD'
						| translate
				}}"
				target="_blank"
				rel="noopener noreferrer"
			>
				{{
					"NAVBAR.NAVIGATION_CENTRAL.CREATE_ACCOUNT_ASAAS.FORGOT_PASSWORD"
						| translate
				}}</a
			>
			{{
				"NAVBAR.NAVIGATION_CENTRAL.CREATE_ACCOUNT_ASAAS.SUCCESS_MESSAGE_END"
					| translate
			}}
		</ng-template>
	</app-alerts>
	<div class="container-fluid navbar-container-fluid">
		<div
			class="business-info ms-2 w-100"
			*ngIf="canShowBusinessInfoNavbar()"
		>
			<strong>{{ getBusinessData()?.nomeFantasia }}</strong>
		</div>

		<i
			class="bi bi-x fs-2 ms-4 responsive-menu-icon d-lg-none"
			(click)="dropdownUserMenu.hide(); dropdownCentralApp.hide()"
			*ngIf="!showNavbarContent"
		></i>
		<span class="d-inline-block d-lg-none ms-3" *ngIf="!showNavbarContent">
			<strong class="fs-5">{{ menuTitle | translate }}</strong>
		</span>
		<i
			[ngClass]="{
				'bi-list': !mobileSidebarVisible,
				'bi-x': mobileSidebarVisible
			}"
			class="bi fs-2 ms-4 responsive-menu-icon d-lg-none"
			(click)="showMobileSidebar()"
			*ngIf="showNavbarContent"
		></i>
		<span
			class="d-inline-block d-lg-none ms-3 page-name"
			*ngIf="showNavbarContent"
		>
			{{ pageName | translate }}
		</span>
		<div
			[ngClass]="{
				'hide-mobile-sidebar': !mobileSidebarVisible,
				'show-mobile-sidebar': mobileSidebarVisible
			}"
			class="mobile-sidebar"
		>
			<app-sidebar
				*ngIf="isMobile"
				[isMobile]="true"
				(closeMobileSidebar)="closeMobileSidebar($event)"
			></app-sidebar>
		</div>
		<div class="collapse navbar-collapse" id="baseNavbar">
			<ul class="navbar-nav ms-auto mt-0 mb-lg-0 me-2">
				<li
					class="nav-item dropdown py-2 me-2 d-none d-md-block"
					*ngxPermissionsOnly="['USUARIO_GRUPO', 'USUARIO_ADM']"
					(click)="segmentTrackTraning()"
				>
					<a
						class="nav-link item-menu-navbar"
						[title]="'NAVBAR.TRAINING' | translate"
						href="https://www.youtube.com/playlist?list=PLr_JZjqvZaCczDj_gZJXWNw6l4eOG0y_5"
						target="_blank"
						rel="noopener noreferrer"
						*ngIf="showNavbarContent"
					>
						<img src="/assets/icons/navbar/channel.svg" />
					</a>
				</li>

				<li
					class="nav-item dropdown py-2"
					(click)="closeMobileSidebar('')"
					[hidden]="!canShowAsaasAppsCenter()"
				>
					<div
						dropdown
						#dropdownCentralApp="bs-dropdown"
						[autoClose]="!isMobile"
						(onHidden)="closeMobileDropdown()"
						(onShown)="
							openMobileDropdown(
								'NAVBAR.NAVIGATION_CENTRAL.TITLE'
							)
						"
					>
						<a
							dropdownToggle
							class="nav-link item-menu-navbar"
							role="button"
							title="Central de Apps"
							(click)="activateCentralAppsLink()"
							[class.menu-activated]="centralAppsLinkActivated"
							[ngClass]="{
								'show-user-menu': showNavbarContent,
								'hide-user-menu': !showNavbarContent
							}"
						>
							<img src="/assets/icons/navbar/apps_central.svg" />
						</a>
						<ul
							class="dropdown-menu dropdown-menu-apps dropdown-menu-end"
							id="dropdown-central-app"
							*dropdownMenu
							role="menu"
							aria-labelledby="dropdown-central-app"
						>
							<div
								class="menu-title mb-2 ms-4 text-start d-none d-lg-block"
							>
								{{
									"NAVBAR.NAVIGATION_CENTRAL.TITLE"
										| translate
								}}
							</div>
							<a href="https://www.asaas.com/" target="_blank">
								<div class="pt-1 item-central-app">
									<li
										class="d-flex ms-4 mb-5 item-dropdown-menu"
									>
										<img
											src="/assets/images/asaas_logo.svg"
											alt=""
										/>
										<div class="ms-3">
											<span
												class="fw-bolder d-block mb-1 app-title"
											>
												{{
													"NAVBAR.NAVIGATION_CENTRAL.ASAAS.TITLE"
														| translate
												}}
											</span>
											<p
												class="me-4 mb-2 app-description"
											>
												{{
													"NAVBAR.NAVIGATION_CENTRAL.ASAAS.TEXT"
														| translate
												}}
											</p>

											<span class="link-to-other-app">
												{{
													"NAVBAR.NAVIGATION_CENTRAL.ASAAS.LINK"
														| translate
												}}
											</span>
										</div>
									</li>
								</div>
							</a>
						</ul>
					</div>
				</li>

				<li
					class="nav-item dropdown py-2"
					(click)="closeMobileSidebar('')"
					[hidden]="canShowAsaasAppsCenter()"
				>
					<div
						dropdown
						#dropdownCentralApp="bs-dropdown"
						[autoClose]="!isMobile"
						(onHidden)="closeMobileDropdown()"
						(onShown)="
							openMobileDropdown(
								'NAVBAR.NAVIGATION_CENTRAL.TITLE'
							)
						"
					>
						<a
							dropdownToggle
							class="nav-link item-menu-navbar"
							role="button"
							title="Central de Apps"
							(click)="activateCentralAppsLink()"
							[class.menu-activated]="centralAppsLinkActivated"
							[ngClass]="{
								'show-user-menu': showNavbarContent,
								'hide-user-menu': !showNavbarContent
							}"
						>
							<img src="/assets/icons/navbar/apps_central.svg" />
						</a>
						<ul
							class="dropdown-menu dropdown-menu-apps dropdown-menu-end"
							id="dropdown-central-app"
							*dropdownMenu
							role="menu"
							aria-labelledby="dropdown-central-app"
						>
							<div
								class="menu-title mb-2 ms-4 text-start d-none d-lg-block"
							>
								{{
									"NAVBAR.NAVIGATION_CENTRAL.TITLE"
										| translate
								}}
							</div>

							<a
								[href]="ASAAS_LOGIN_URL"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div class="pt-1 item-central-app">
									<li
										class="d-flex ms-4 mb-5 item-dropdown-menu"
									>
										<img
											src="/assets/images/asaas_logo.svg"
											alt=""
										/>
										<div class="ms-3">
											<span
												class="fw-bolder d-block mb-1 app-title"
											>
												{{
													"NAVBAR.NAVIGATION_CENTRAL.ASAAS.TITLE"
														| translate
												}}
											</span>
											<p
												class="me-4 mb-2 app-description"
											>
												{{
													"NAVBAR.NAVIGATION_CENTRAL.ASAAS.TEXT"
														| translate
												}}
											</p>

											<span class="link-to-other-app">
												{{
													"NAVBAR.NAVIGATION_CENTRAL.ASAAS.LINK"
														| translate
												}}
											</span>
										</div>
									</li>
								</div>
							</a>
						</ul>
					</div>
				</li>

				<li class="nav-item dropdown" (click)="closeMobileSidebar('')">
					<div
						dropdown
						#dropdownUserMenu="bs-dropdown"
						[autoClose]="!isMobile"
						(onHidden)="closeMobileDropdown()"
						(onShown)="openMobileDropdown(userEmail)"
					>
						<a
							dropdownToggle
							class="nav-link pt-0 pe-0"
							role="button"
							(click)="activateUserLink()"
							[ngClass]="{
								'show-user-menu': showNavbarContent,
								'hide-user-menu': !showNavbarContent
							}"
						>
							<div
								class="d-flex justify-content-center profile p-2"
								[class.menu-activated]="userLinkActivated"
							>
								<span
									*ngIf="emailsWithError > 0"
									class="icon-badge icon-menu-profile"
								>
									{{ emailsWithError }}
								</span>
								<img
									class="avatar_image me-2"
									[src]="
										userAvatar
											? userAvatar
											: 'assets/profile_avatar.svg'
									"
									alt=""
								/>
								<img
									src="/assets/icons/arrow_down.svg"
									alt=""
									class="d-none d-lg-block"
									[class.flip-arrow-up]="userLinkActivated"
									[class.flip-arrow-down]="!userLinkActivated"
								/>
							</div>
						</a>

						<ul
							class="dropdown-menu dropdown-menu-end"
							id="dropdown-usermenu"
							*dropdownMenu
							role="menu"
							aria-labelledby="dropdown-usermenu"
						>
							<li class="item-dropdown-menu">
								<div
									class="menu-title mb-4 d-none d-lg-block px-3 profile-email-wrap"
								>
									{{ userEmail }}
								</div>
								<a
									class="dropdown-item"
									[routerLink]="[
										'/configuracoes/informacoes'
									]"
									[queryParams]="{ refreshTabs: true }"
									(click)="
										dropdownUserMenu.hide();
										closeMobileSidebar(
											'NAVBAR.USER_MENU.TOOLS'
										)
									"
									><img
										src="/assets/icons/navbar/settings.svg"
									/>
									<span class="ms-3">{{
										"NAVBAR.USER_MENU.TOOLS" | translate
									}}</span>
								</a>
							</li>
							<li
								class="item-dropdown-menu d-md-none"
								*ngxPermissionsOnly="[
									'USUARIO_GRUPO',
									'USUARIO_ADM'
								]"
							>
								<a
									class="dropdown-item"
									(click)="dropdownUserMenu.hide()"
									href="https://www.youtube.com/playlist?list=PLr_JZjqvZaCczDj_gZJXWNw6l4eOG0y_5"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src="/assets/icons/navbar/channel.svg"
										class="scale-icon"
									/><span class="ms-3"
										>{{ "NAVBAR.TRAINING" | translate }}
									</span>
								</a>
							</li>
							<li
								class="item-dropdown-menu"
								*ngxPermissionsOnly="[
									'USUARIO_GRUPO',
									'USUARIO_ADM'
								]"
							>
								<a
									[routerLink]="[
										'/configuracoes/log-de-email'
									]"
									class="dropdown-item"
									(click)="dropdownUserMenu.hide()"
									(click)="
										closeMobileSidebar('NAVBAR.LOG_EMAILS')
									"
								>
									<i class="bi bi-envelope fs-5"></i>
									<span class="ms-3"
										>{{ "NAVBAR.LOG_EMAILS" | translate }}
									</span>
								</a>
							</li>
							<li class="item-dropdown-menu">
								<a
									class="dropdown-item"
									(click)="dropdownUserMenu.hide()"
									href="https://ajuda.asaas.com/pt-BR/articles/1442393-termos-contratos-e-politicas"
									target="_blank"
									rel="noopener noreferrer"
									><img
										src="/assets/icons/navbar/paper.svg"
									/><span class="ms-3">{{
										"NAVBAR.USER_MENU.CONTRACT" | translate
									}}</span></a
								>
							</li>
							<li class="item-dropdown-menu">
								<a
									class="cursor-pointer dropdown-item"
									(click)="dropdownUserMenu.hide()"
									(click)="logout()"
									><img
										src="/assets/icons/navbar/logout.svg"
									/><span class="ms-3">{{
										"NAVBAR.USER_MENU.LOGOUT" | translate
									}}</span></a
								>
							</li>
						</ul>
					</div>
				</li>
			</ul>
		</div>
	</div>
</nav>
