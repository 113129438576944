import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class ServiceWorkerService {
	private newVersionAvailable = new BehaviorSubject<boolean>(false);
	newVersionAvailable$ = this.newVersionAvailable.asObservable();

	constructor(private updates: SwUpdate) {}

	private async checkForUpdates(): Promise<void> {
		await this.updates.checkForUpdate();
	}

	private reloadApp(): void {
		window.location.reload();
	}

	initialize(): void {
		if (this.updates.isEnabled) {
			this.updates.versionUpdates.subscribe((evt) => {
				if (evt.type === "VERSION_READY") {
					this.newVersionAvailable.next(true);
					this.reloadApp();
				}
			});

			this.updates.unrecoverable.subscribe((_) => {
				this.reloadApp();
			});
		}
	}
}
