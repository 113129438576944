/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const rr = (r, o, t) => {
  for (const t of o) if (t[0] === r) return (0, t[1])();
  return t?.();
};
export { rr as choose };
