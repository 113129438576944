import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2 } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class LoadScriptService {
	constructor(@Inject(DOCUMENT) private document: Document) {}

	loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
		const script = renderer.createElement("script") as HTMLScriptElement;
		script.type = "text/javascript";
		script.src = src;
		renderer.appendChild(this.document.body, script);
		return script;
	}
}
