import { Injectable } from "@angular/core";
import { EmailsWithError } from "@data/schemas/email-with-error";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { map, Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class LogEmailsService {
	API_URL = "";

	constructor(private data: BaseCrudBffService) {}

	emailsWithError(): Observable<number> {
		return this.data
			.getAll<EmailsWithError>({
				path: "logs-emails/number-emails-with-error"
			})
			.pipe(map((result) => result.numberOfEmails));
	}
}
