import { desc as t } from "./base.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function e(e, r) {
  return (n, s, i) => {
    const o = t => t.renderRoot?.querySelector(e) ?? null;
    if (r) {
      const {
        get: e,
        set: r
      } = "object" == typeof s ? n : i ?? (() => {
        const t = Symbol();
        return {
          get() {
            return this[t];
          },
          set(e) {
            this[t] = e;
          }
        };
      })();
      return t(n, s, {
        get() {
          let t = e.call(this);
          return void 0 === t && (t = o(this), (null !== t || this.hasUpdated) && r.call(this, t)), t;
        }
      });
    }
    return t(n, s, {
      get() {
        return o(this);
      }
    });
  };
}
export { e as query };
