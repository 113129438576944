@if (breadcrumbs().length > 0) {
	<div class="atlas-spacing-mx-3 atlas-spacing-mt-3">
		<atlas-breadcrumb slot="breadcrumb">
			@for (breadcrumb of breadcrumbs(); track $index) {
				<atlas-breadcrumb-item
					appAutomataNavigator
					[href]="breadcrumb.showBreadcrumbLink ? breadcrumb.url : ''"
					[text]="breadcrumb.label | translate"
					[icon]="breadcrumb.icon"
				/>
			}
		</atlas-breadcrumb>
	</div>
}
