<ngx-loading-bar
	color="#3459e6"
	height="4px"
	[includeSpinner]="false"
></ngx-loading-bar>

<app-alerts key="global-toast" [toastMode]="true" [timeToHideToast]="3000" />

<app-loading-overlay key="global-loading-overlay"></app-loading-overlay>

@if (showChecklist() && this.authService.canGoToHome()) {
	<app-checklist-sidebar></app-checklist-sidebar>
}

@if (showWelcomeModal() && this.authService.canGoToHome()) {
	<app-welcome-modal></app-welcome-modal>
}

<app-base appAutomataBreadcrumb>
	@if (false) {
		<app-automata-breadcrumb />
	}
	<router-outlet></router-outlet>
</app-base>
