import { ReactiveElement as e } from "@lit/reactive-element";
export * from "@lit/reactive-element";
import { render as n, noChange as l } from "lit-html";
export * from "lit-html";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
class h extends e {
  constructor() {
    super(...arguments), this.renderOptions = {
      host: this
    }, this.o = void 0;
  }
  createRenderRoot() {
    const t = super.createRenderRoot();
    return this.renderOptions.renderBefore ??= t.firstChild, t;
  }
  update(t) {
    const e = this.render();
    this.hasUpdated || (this.renderOptions.isConnected = this.isConnected), super.update(t), this.o = n(e, this.renderRoot, this.renderOptions);
  }
  connectedCallback() {
    super.connectedCallback(), this.o?.setConnected(!0);
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.o?.setConnected(!1);
  }
  render() {
    return l;
  }
}
h._$litElement$ = !0, h["finalized"] = !0, globalThis.litElementHydrateSupport?.({
  LitElement: h
});
const f = globalThis.litElementPolyfillSupport;
f?.({
  LitElement: h
});
const x = {
  _$AK: (t, e, s) => {
    t._$AK(e, s);
  },
  _$AL: t => t._$AL
};
(globalThis.litElementVersions ??= []).push("4.1.0");
export { h as LitElement, x as _$LE };
