import { CommonModule } from "@angular/common";
import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	signal,
	type OnInit,
	OnDestroy
} from "@angular/core";
import { ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { AutomataNavigatorDirective } from "@shared/directives/automata-navigator.directive";
import { Subject, filter, takeUntil } from "rxjs";
import { TranslateModule } from "@ngx-translate/core";

interface Breadcrumb {
	label: string;
	url: string;
	showBreadcrumbLink: boolean;
	icon?: string;
}

@Component({
	selector: "app-automata-breadcrumb",
	standalone: true,
	imports: [CommonModule, AutomataNavigatorDirective, TranslateModule],
	templateUrl: "./automata-breadcrumb.component.html",
	styleUrl: "./automata-breadcrumb.component.css",
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutomataBreadcrumbComponent implements OnInit, OnDestroy {
	constructor(private router: Router) {}

	private _onDestroy = new Subject<boolean>();

	protected breadcrumbs = signal<Breadcrumb[]>([]);

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this._onDestroy)
			)
			.subscribe((_) => {
				const snapshotRoot = this.router.routerState.snapshot.root;
				const breadcrumbs = this.createBreadcrumbList(snapshotRoot);
				this.breadcrumbs.set(breadcrumbs);
			});
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
	}

	private checkBreadcrumbStatus(route: ActivatedRouteSnapshot): {
		routeFirstChild: ActivatedRouteSnapshot | null;
		routeDataBreadcrumb: string | undefined;
	} {
		const routeFirstChild = route.firstChild;
		const routeDataBreadcrumb = route.data.breadcrumb
			? String(route.data.breadcrumb)
			: undefined;
		return { routeFirstChild, routeDataBreadcrumb };
	}

	private createBreadcrumb(
		route: ActivatedRouteSnapshot,
		routeUrl: string[]
	): Breadcrumb {
		const breadcrumb: Breadcrumb = {
			label: String(route.data.breadcrumb) ?? "",
			url: "#/" + routeUrl.join("/"),
			icon: String(route.data.breadcrumbIcon) ?? "",
			showBreadcrumbLink: Boolean(route.data.showBreadcrumbLink)
		};
		return breadcrumb;
	}

	private createBreadcrumbList(
		route: ActivatedRouteSnapshot,
		parentUrl: string[] = [],
		breadcrumbs: Breadcrumb[] = []
	): Breadcrumb[] {
		const { routeDataBreadcrumb, routeFirstChild } =
			this.checkBreadcrumbStatus(route);
		const routeUrl = [...parentUrl, ...route.url.map((url) => url.path)];

		if (routeDataBreadcrumb) {
			const hasBreadcrumb = breadcrumbs.findIndex(
				(breadcrumb) =>
					breadcrumb.label === String(route.data.breadcrumb)
			);
			if (hasBreadcrumb === -1) {
				const breadcrumb = this.createBreadcrumb(route, routeUrl);
				breadcrumbs.push(breadcrumb);
			}
		}

		if (routeFirstChild) {
			this.createBreadcrumbList(routeFirstChild, routeUrl, breadcrumbs);
		}

		return breadcrumbs;
	}
}
