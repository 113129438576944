import { desc as t } from "./base.js";
/**
 * @license
 * Copyright 2021 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
function o(o) {
  return (e, n) => {
    const {
        slot: r,
        selector: s
      } = o ?? {},
      c = "slot" + (r ? `[name=${r}]` : ":not([name])");
    return t(e, n, {
      get() {
        const t = this.renderRoot?.querySelector(c),
          e = t?.assignedElements(o) ?? [];
        return void 0 === s ? e : e.filter(t => t.matches(s));
      }
    });
  };
}
export { o as queryAssignedElements };
