import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpParams
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class TranslateCacheInterceptor implements HttpInterceptor {
	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		if (request.url.includes("/assets/i18n")) {
			return next.handle(
				request.clone({
					params: new HttpParams().set("ngsw-bypass", true)
				})
			);
		}

		return next.handle(request);
	}
}
