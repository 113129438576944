import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { EnvironmentConfig, ENV_CONFIG } from "./environment-config";

@NgModule({
	imports: [CommonModule]
})
export class EnvironmentModule {
	static forRoot(
		config: EnvironmentConfig
	): ModuleWithProviders<EnvironmentModule> {
		return {
			ngModule: EnvironmentModule,
			providers: [
				{
					provide: ENV_CONFIG,
					useValue: config
				}
			]
		};
	}
}
