import { HttpClient, HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgxEchartsModule } from "ngx-echarts";
import * as echarts from "echarts/core";
import { LineChart, PieChart } from "echarts/charts";
import { BarChart } from "echarts/charts";
import {
	TitleComponent,
	TooltipComponent,
	GridComponent,
	LegendComponent
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgxPermissionsModule } from "ngx-permissions";
import "@asaasdev/atlas";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "@core/core.module";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { environment } from "src/environments/environment";
import { NavbarComponent } from "./layout/navbar/navbar.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { SidebarMenuComponent } from "./layout/sidebar/sidebar-menu/sidebar-menu.component";

import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarModule, LOADING_BAR_CONFIG } from "@ngx-loading-bar/core";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BaseComponent } from "./layout/base/base.component";
import { SharedModule } from "@shared/shared.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { PopoverModule } from "ngx-bootstrap/popover";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { NgScrollbarModule } from "ngx-scrollbar";
import { AutomataBreadcrumbComponent } from "@shared/components/automata-breadcrumb/automata-breadcrumb.component";
import { ChecklistSidebarComponent } from "@modules/home/components/checklist-sidebar/checklist-sidebar.component";
import { WelcomeModalComponent } from "@modules/home/components/welcome-modal/welcome-modal.component";

echarts.use([
	TitleComponent,
	TooltipComponent,
	GridComponent,
	BarChart,
	LineChart,
	LegendComponent,
	PieChart,
	CanvasRenderer
]);

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(httpClient);
}

@NgModule({
	declarations: [
		AppComponent,
		SidebarComponent,
		NavbarComponent,
		FooterComponent,
		SidebarMenuComponent,
		BaseComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		BrowserModule,
		AppRoutingModule,
		CoreModule,
		HttpClientModule,
		BrowserAnimationsModule,
		SharedModule,
		NgSelectModule,
		NgScrollbarModule,
		LoadingBarHttpClientModule,
		LoadingBarModule,
		BsDatepickerModule.forRoot(),
		TabsModule.forRoot(),
		PaginationModule.forRoot(),
		BsDropdownModule.forRoot(),
		NgxEchartsModule.forRoot({ echarts }),
		ToastrModule.forRoot({
			positionClass: "toast-top-center",
			maxOpened: 1,
			autoDismiss: true
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		NgxPermissionsModule.forRoot(),
		ModalModule.forRoot(),
		CollapseModule.forRoot(),
		PopoverModule.forRoot(),
		NgxMaskDirective,
		NgxMaskPipe,
		ServiceWorkerModule.register("safety-worker.js", {
			enabled: environment.production,
			registrationStrategy: "registerImmediately"
		}),
		AutomataBreadcrumbComponent,
		ChecklistSidebarComponent,
		WelcomeModalComponent
	],
	bootstrap: [AppComponent],
	providers: [
		{ provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 800 } },
		provideNgxMask()
	]
})
export class AppModule {}
