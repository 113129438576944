import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NgxPermissionsGuard } from "ngx-permissions";
import { loggedInAuthGuardFn } from "@core/guards/logged-in-auth.guard";
import { authGuardFn } from "@core/guards/auth.guard";
import { isSupportGuardFn } from "@core/guards/is-support.guard";
import { devEnvGuardFn } from "@core/guards/dev-env.guard";

const routes: Routes = [
	{
		path: "",
		redirectTo: "/home",
		pathMatch: "full"
	},
	{
		path: "home",
		loadChildren: () =>
			import("./modules/home/home.module").then((m) => m.HomeModule),
		canMatch: [authGuardFn],
		data: {
			breadcrumbIcon: "home"
		}
	},
	{
		path: "pages/auth",
		loadChildren: () =>
			import("./modules/auth/auth.module").then((m) => m.AuthModule),
		canMatch: [loggedInAuthGuardFn]
	},
	{
		path: "financeiro",
		loadChildren: () =>
			import("./modules/financeiro/financeiro.module").then(
				(m) => m.FinanceiroModule
			),
		canMatch: [NgxPermissionsGuard, authGuardFn],
		data: {
			permissions: {
				only: ["FINANCEIRO"],
				redirectTo: "/pages/auth/login"
			},
			breadcrumb: "FINANCIAL_TRANSACTIONS.LABEL.FINANCIAL",
			breadcrumbIcon: "dollar-sign"
		}
	},
	{
		path: "comercial",
		loadChildren: () =>
			import("./modules/commercial/commercial.module").then(
				(m) => m.CommercialModule
			),
		canMatch: [NgxPermissionsGuard, authGuardFn],
		data: {
			permissions: {
				only: ["COMERCIAL"],
				redirectTo: "/pages/auth/login"
			},
			breadcrumb: "Commercial",
			breadcrumbIcon: "briefcase",
			showBreadcrumbLink: false
		}
	},
	{
		path: "contabilidade",
		loadChildren: () =>
			import("./modules/accounting/accounting.module").then(
				(m) => m.AccountingModule
			),
		canMatch: [NgxPermissionsGuard, authGuardFn],
		data: {
			permissions: {
				only: ["CONTABILIDADEONLINE"],
				redirectTo: "/pages/auth/login"
			}
		}
	},
	{
		path: "ponto-de-venda",
		loadChildren: () =>
			import("./modules/sales-point/sales-point.module").then(
				(m) => m.SalesPointModule
			),
		canMatch: [NgxPermissionsGuard, authGuardFn],
		data: {
			permissions: {
				only: ["PONTODEVENDA"],
				redirectTo: "/pages/auth/login"
			}
		}
	},
	{
		path: "faturamento",
		loadChildren: () =>
			import("./modules/invoicing/invoicing.module").then(
				(m) => m.InvoicingModule
			),
		canMatch: [NgxPermissionsGuard, authGuardFn],
		data: {
			permissions: {
				only: ["FATURAMENTO"],
				redirectTo: "/pages/auth/login"
			},
			breadcrumb: "BREADCRUMB.REVENUES.LABEL",
			breadcrumbIcon: "dollar-sign"
		}
	},
	{
		path: "suprimentos",
		loadChildren: () =>
			import("@modules/supplies/supplies.module").then(
				(m) => m.SuppliesModule
			),
		canMatch: [NgxPermissionsGuard, authGuardFn],
		data: {
			permissions: {
				only: ["SUPRIMENTOS"],
				redirectTo: "/pages/auth/login"
			}
		}
	},
	{
		path: "producao",
		loadChildren: () =>
			import("./modules/production/production.module").then(
				(m) => m.ProductionModule
			),
		canMatch: [NgxPermissionsGuard, authGuardFn],
		data: {
			permissions: {
				only: ["PRODUCAO"],
				redirectTo: "/pages/auth/login"
			}
		}
	},
	{
		path: "servicos",
		loadChildren: () =>
			import("./modules/servicos/servicos.module").then(
				(m) => m.ServicosModule
			),
		canMatch: [NgxPermissionsGuard, authGuardFn],
		data: {
			permissions: {
				only: ["SERVICOS"],
				redirectTo: "/pages/auth/login"
			}
		},
		runGuardsAndResolvers: "always"
	},
	{
		path: "relatorios",
		children: [
			{
				path: "faturamento",
				loadChildren: () =>
					import(
						"./modules/relatorios/faturamento/faturamento.module"
					).then((m) => m.FaturamentoModule),
				canMatch: [NgxPermissionsGuard, authGuardFn],
				data: {
					permissions: {
						only: ["RELFATURAMENTO"],
						redirectTo: "/pages/auth/login"
					}
				}
			},
			{
				path: "financeiro",
				loadChildren: () =>
					import(
						"./modules/relatorios/financeiro/financeiro.module"
					).then((m) => m.FinanceiroModule),
				canMatch: [NgxPermissionsGuard, authGuardFn],
				data: {
					permissions: {
						only: ["RELFINANCEIRO"],
						redirectTo: "/pages/auth/login"
					}
				}
			},
			{
				path: "producao",
				loadChildren: () =>
					import(
						"./modules/relatorios/producao/producao.module"
					).then((m) => m.ProducaoModule),
				canMatch: [NgxPermissionsGuard, authGuardFn],
				data: {
					permissions: {
						only: ["RELPRODUCAO"],
						redirectTo: "/pages/auth/login"
					}
				}
			},
			{
				path: "servicos",
				loadChildren: () =>
					import(
						"./modules/relatorios/servicos/servicos.module"
					).then((m) => m.ServicosModule),
				canMatch: [NgxPermissionsGuard, authGuardFn],
				data: {
					permissions: {
						only: ["RELSERVICOS"],
						redirectTo: "/pages/auth/login"
					}
				}
			},
			{
				path: "comercial",
				loadChildren: () =>
					import(
						"./modules/relatorios/comercial/comercial.module"
					).then((m) => m.ComercialModule),
				canMatch: [NgxPermissionsGuard, authGuardFn],
				data: {
					permissions: {
						only: ["RELCOMERCIAL"],
						redirectTo: "/pages/auth/login"
					}
				}
			},
			{
				path: "estoque",
				loadChildren: () =>
					import("./modules/relatorios/estoque/estoque.module").then(
						(m) => m.EstoqueModule
					),
				canMatch: [NgxPermissionsGuard, authGuardFn],
				data: {
					permissions: {
						only: ["RELESTOQUE"],
						redirectTo: "/pages/auth/login"
					}
				}
			}
		]
	},
	{
		path: "suporte",
		loadChildren: () =>
			import("./modules/support/support.module").then(
				(m) => m.SupportModule
			),
		canMatch: [isSupportGuardFn]
	},
	{
		path: "perfil",
		loadChildren: () =>
			import("./modules/profile/profile.module").then(
				(m) => m.ProfileModule
			),
		canMatch: [authGuardFn]
	},
	{
		path: "configuracoes",
		loadChildren: () =>
			import("./modules/preferences/preferences.module").then(
				(m) => m.PreferencesModule
			),
		canMatch: [authGuardFn]
	},
	{
		path: "jsf-probe-tool",
		loadChildren: () =>
			import("./modules/jsf-probe-tool/jsf-probe-tool.module").then(
				(m) => m.JsfProbeToolModule
			),
		canMatch: [authGuardFn]
	},
	{
		path: "atlas-playground",
		loadComponent: () =>
			import(
				"./modules/atlas-playground/pages/atlas/atlas.component"
			).then((m) => m.AtlasComponent),
		canMatch: [authGuardFn, devEnvGuardFn]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
